<template>
  <div>
    <v-alert
      class="mx-2"
      dismissible
      v-model="showMsg"
      type="warning"
      v-if="errorMsg"
    >
      {{ errorMsg }}
    </v-alert>

    <v-card>
      <v-data-table :headers="headers" :loading="loading" :items="items">
        <template v-slot:item.packedTime="{ item }">
          {{ item.packedTime | formatDateTimeYear }}
        </template>
        <template v-slot:item.worksheetId="{ item }">
          <router-link
            target="_blank"
            :to="{
              name: 'processWorksheet',
              query: { wsId: item.worksheetId },
            }"
          >
            {{ item.worksheetId }}
          </router-link>
        </template>
        <template v-slot:item.salesOrderId="{ item }">
          <a
            target="_blank"
            :href="`${domain}/office/sales/order/${item.salesOrderId}?view=detail#packagepanel`"
          >
            {{ item.packagingCode }}
          </a>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn @click="deleteItem(item)" icon>
            <v-icon> mdi-delete-outline </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <!-- <v-card-title class="d-flex justify-space-between">
        <span>
          Code:
          <a
            v-if="domain"
            target="_blank"
            :href="`https://prod.${domain}.com/office/sales/order/${packingList.salesOrderId}?view=detail#packagepanel`"
          >
            {{ packingList.packagingCode }}
          </a>
          <span v-else>
            {{ packingList.packagingCode }}
          </span>
        </span>
        <span>
          Packaged on: {{ packingList.packedTime | formatDateTimeYear }}
        </span>
      </v-card-title>
      <v-card-text class="text-center">
        <div v-if="packingList.worksheetId">
          Worksheet
          <router-link
            target="_blank"
            :to="{
              name: 'processWorksheet',
              query: { wsId: packingList.worksheetId },
            }"
          >
            {{ packingList.worksheetId }}
          </router-link>
        </div>
        <div v-else>No worksheet associated with this packing list</div>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="deletePackingList"> Delete </v-btn>
      </v-card-actions> -->
    </v-card>
    <confirmation-dialog ref="confirm" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { deletePackingList } from "@/services/orderServices";
import { parseDomain } from "parse-domain";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    errorMsg: null,
    showMsg: false,
    domain: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.labelDetail.code"),
          value: "packagingCode",
          align: "start",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.base.time"),
          value: "packedTime",
          align: "start",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.worksheet.worksheet"),
          value: "worksheetId",
          align: "start",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.packaging.orderId"),
          value: "salesOrderId",
          align: "start",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.packaging.packer"),
          value: "packer",
          align: "start",
          sortable: true,
        },
        // {
        //   text: this.$vuetify.lang.t("$vuetify.packaging.packagedWeight"),
        //   value: "packagedWeight",
        //   align: "start",
        //   sortable: true,
        // },
        // {
        //   text: this.$vuetify.lang.t("$vuetify.base.notes"),
        //   value: "notes",
        //   align: "start",
        //   sortable: true,
        // },
        {
          text: "",
          value: "actions",
          align: "start",
          sortable: true,
        },
      ];
    },
  },
  created() {
    const parseResult = parseDomain(window.location.hostname);
    const { domain } = parseResult;
    this.domain = domain ? `https://prod.${domain}.com` : 'http://localhost:8070';
  },
  methods: {
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),
    async deleteItem(packingList) {
      let confirm = await this.$refs.confirm.open(
        "Confirm Delete",
        "Do you want to delete this packing list?"
      );
      if (confirm) {
        console.log("deleting packing list");
        deletePackingList(packingList.id)
          .then(() => {
            this.$emit("updated", packingList);
          })
          .catch((error) => {
            this.errorMsg = error.data.message;
            this.showMsg = true;
          });
      }
    },
    viewWorksheet(wsId) {
      this.$router.push({ name: "processWorksheet", query: { wsId } });
    },
  },
};
</script>

<style>
</style>